import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { from, merge, Observable, timer, zip } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMap } from 'rxjs/operators';

import { GetCartTotalsQuery } from '../../../common/generated-types';
import { DataService } from '../../providers/data/data.service';
import { StateService } from '../../providers/state/state.service';

import { GET_CART_TOTALS } from './cart-toggle.graphql';

@Component({
  selector: 'vsf-cart-toggle',
  templateUrl: './cart-toggle.component.html',
  styleUrls: ['./cart-toggle.component.scss'],
  standalone: false,
})
export class CartToggleComponent implements OnInit {
  @Output() toggle = new EventEmitter<void>();
  cart$: Observable<{ total: number; quantity: number }>;
  cartChangeIndication$: Observable<boolean>;

  constructor(
    private dataService: DataService,
    private stateService: StateService,
  ) {}

  ngOnInit() {
    this.cart$ = merge(
      this.stateService.select(state => state.activeOrderId),
      this.stateService.select(state => state.signedIn),
    ).pipe(
      switchMap(() =>
        this.dataService.query<GetCartTotalsQuery>({
          query: GET_CART_TOTALS,
          variables: {},
          fetchPolicy: 'network-only',
        }),
      ),
      map(({ activeOrder }) => {
        return {
          total: activeOrder ? activeOrder.totalWithTax : 0,
          quantity: activeOrder ? activeOrder.totalQuantity : 0,
        };
      }),
      shareReplay(1),
    );
    this.cartChangeIndication$ = this.cart$.pipe(
      map(cart => cart.quantity),
      distinctUntilChanged(),
      switchMap(() => zip(from([true, false]), timer(0, 1000), val => val)),
    );
  }
}
