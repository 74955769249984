import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import {
  GetActiveCustomerQuery,
  SignInMutation,
  SignInMutationVariables,
} from '../../../common/generated-types';
import { DataService } from '../../../core/providers/data/data.service';
import { StateService } from '../../../core/providers/state/state.service';

import { SIGN_IN } from './sign-in.graphql';
import { GET_ACTIVE_CUSTOMER } from 'src/app/common/graphql/documents.graphql';

@Component({
  selector: 'vsf-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SignInComponent {
  @Input() navigateToOnSuccess: any[] | undefined;
  @Input() displayRegisterLink = true;

  emailAddress: string;
  password: string;
  rememberMe = false;
  invalidCredentials = false;
  signInDisabled = false;

  constructor(
    private dataService: DataService,
    private stateService: StateService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
  ) {}

  signIn() {
    this.signInDisabled = true;
    let commands: any[] | undefined;
    this.dataService
      .mutate<SignInMutation, SignInMutationVariables>(SIGN_IN, {
        emailAddress: this.emailAddress,
        password: this.password,
        rememberMe: this.rememberMe,
      })
      .subscribe({
        next: ({ login }) => {
          switch (login.__typename) {
            case 'CurrentUser':
              this.stateService.setState('signedIn', true);
              this.dataService
                .query<GetActiveCustomerQuery>({
                  query: GET_ACTIVE_CUSTOMER,
                  fetchPolicy: 'network-only',
                })
                .subscribe();
              commands = this.navigateToOnSuccess || ['/'];
              this.router.navigate(commands);
              this.signInDisabled = false;
              break;
            case 'NativeAuthStrategyError':
            case 'InvalidCredentialsError':
              this.displayCredentialsError();
              this.signInDisabled = false;
              break;
          }
        },
      });
  }

  private displayCredentialsError() {
    this.invalidCredentials = false;
    this.changeDetector.markForCheck();
    setTimeout(() => {
      this.invalidCredentials = true;
      this.changeDetector.markForCheck();
    }, 50);
  }
}
