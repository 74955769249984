<a
  [routerLink]="['/category/', collection.slug]"
  class="block max-w-[300px] relative rounded-lg overflow-hidden hover:opacity-75 xl:w-auto"
>
  <span aria-hidden="true" class="">
    <div class="w-full h-full object-center object-cover">
      <img
        *ngIf="collection.featuredAsset; else placeholderImage"
        alt="{{ collection.name }}"
        [src]="collection.featuredAsset | assetPreview: 300 : 300"
        class="w-[170px] h-[170px] object-cover"
      />
      <ng-template #placeholderImage>
        <div class="w-[170px] h-[170px] bg-gray-200 flex items-center justify-center">
          <svg
            class="w-16 h-16 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
            ></path>
          </svg>
        </div>
      </ng-template>
    </div>
  </span>
  <span
    aria-hidden="true"
    class="absolute w-full bottom-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
  ></span>
  <span class="absolute w-full bottom-2 mt-auto text-center text-xl font-bold text-white">
    {{ collection.name }}
  </span>
</a>
