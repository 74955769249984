<div class="max-w-6xl mx-auto px-4">
  <div class="box-content py-2 px-2 relative overflow-x-auto xl:overflow-visible">
    <div class="flex justify-between">
      <div class="py-4 text-xl font-bold sm:px-6 lg:px-8 px-0 md:px-0 xl:px-0">หมวดหมู่สินค้า</div>
      <div class="py-4 text-xl font-bold sm:px-6 lg:px-8 px-0 md:px-0 xl:px-0">
        <button
          [routerLink]="['/category', 'all']"
          class="rounded-md bg-black px-4 py-2 border border-transparent text-base font-medium text-white"
        >
          ดูทั้งหมด
        </button>
      </div>
    </div>
    <div
      class="grid justify-items-center grid-cols-2 md:grid-cols-6 gap-y-8 gap-x-8 sm:px-6 lg:px-8 xl:relative xl:px-0 xl:space-x-0 xl:gap-x-8"
    >
      <vsf-collection-card
        *ngFor="let collection of collections$ | async"
        [collection]="collection"
      >
      </vsf-collection-card>
    </div>
  </div>
</div>
