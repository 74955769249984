<div
  [class]="
    'flex flex-row px-2 bg-gray-100 rounded-xl mx-4 transition-all duration-300 ease-in-out ' +
    (isInputFocused ? 'w-full' : 'w-60')
  "
>
  <input
    type="text"
    [formControl]="searchTerm"
    (keydown.enter)="doSearch(searchTerm.value)"
    (focus)="isInputFocused = true"
    (blur)="isInputFocused = false"
    class="bg-gray-100 w-full sm:text-sm border-none outline-none focus:ring-0"
    placeholder="ค้นหาสินค้า"
    aria-label="Search input"
  />
  <button type="button" (click)="doSearch(searchTerm.value)" class="px-2">
    <fa-icon icon="search"></fa-icon>
  </button>
</div>
