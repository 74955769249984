<div class="lg:hidden">
  <button
    class="border border-primary-100 rounded bg-primary-50 p-2 text-sm text-primary-700"
    (click)="manuallyExpanded = !manuallyExpanded"
  >
    <fa-icon icon="filter" class="mr-2"></fa-icon>
    Filters
  </button>
</div>
<div class="mb-4 lg:block" [ngClass]="filtersExpanded ? 'block' : 'hidden'">
  <div
    *ngFor="let facet of facets; trackBy: trackById"
    class="text-sm"
    [class.enabled]="activeFacetValueIds.length"
  >
    <div class="h-12 flex items-center font-medium text-gray-900 uppercase">
      {{ facet.name | titlecase }}
    </div>
    <hr class="mb-4" />
    <ul class="space-y-4">
      <li
        *ngFor="let value of facet.values; trackBy: trackById"
        [class.active]="isActive(value.id)"
      >
        <label class=""
          ><input
            type="checkbox"
            [checked]="isActive(value.id)"
            (change)="toggleFacetValueIdInRoute(value.id)"
            class="h-4 w-4 border-gray-300 rounded text-primary-600 focus:ring-primary-500"
          />
          <span class="ml-3 text-sm text-gray-600">{{ value.name }}</span>
        </label>
      </li>
    </ul>
  </div>
</div>
