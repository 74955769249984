<ng-template [ngIf]="product">
  <a class="flex flex-col" prefetch="intent" [routerLink]="['/product', product.slug]">
    <img
      class="rounded-xl flex-grow object-cover aspect-[7/8]"
      alt=""
      [src]="product.productAsset | assetPreview: 300 : 400"
    />
    <div class="h-2"></div>
    <div class="text-sm text-gray-700">
      {{ product.productName }}
    </div>
    <div class="text-sm font-medium text-gray-900">
      <ng-template
        [ngIf]="product.priceWithTax.min === product.priceWithTax.max"
        [ngIfElse]="range"
      >
        {{ product.priceWithTax.min | formatPrice }}
      </ng-template>
      <ng-template #range> From {{ product.priceWithTax.min | formatPrice }} </ng-template>
    </div>
  </a>
</ng-template>
