import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faFacebook, faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import {
  faAddressBook,
  faBars,
  faCheck,
  faChevronRight,
  faCreditCard,
  faFilter,
  faHeart,
  faHome,
  faMinus,
  faPlus,
  faSadCry,
  faSearch,
  faShoppingBag,
  faShoppingCart,
  faSignOutAlt,
  faStar,
  faTachometerAlt,
  faTimes,
  faUser,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';

/**
 * All the icons used in the library are declared here. This allows us to
 * ship only the needed icons so that the bundle does not get bloated.
 */
export function buildIconLibrary(library: FaIconLibrary) {
  library.addIcons(
    faTwitter,
    faFacebook,
    faInstagram,
    faYoutube,
    faTimes,
    faSearch,
    faShoppingCart,
    faPlus,
    faMinus,
    faCheck,
    faChevronRight,
    faFilter,
    faHome,
    faHeart,
    faSadCry,
    faCreditCard,
    faUserCircle,
    faBars,
    faStar,
    // New icons
    faAddressBook,
    faShoppingBag,
    faSignOutAlt,
    faTachometerAlt,
    faUser,
  );
}
