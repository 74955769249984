<vsf-layout>
  <vsf-layout-header>
    <div class="bg-gradient-to-r shadow-xs border-b border-gray-200 transform">
      <div class="max-w-6xl mx-2 py-4 md:mx-auto flex items-center space-x-4 md:px-4 md:space-x-2">
        <vsf-mobile-menu-toggle class="md:hidden"></vsf-mobile-menu-toggle>
        <a [routerLink]="['/']" class="text-xl">
          LOGO
          <!-- <img
            alt="Vendure Storefront"
            class="w-10"
            src="assets/cube-logo-small.webp"
          /> -->
          <span class="hidden xl:inline"> จิตสงวน </span>
        </a>
        <vsf-product-search-bar class="flex-1 flex justify-start"></vsf-product-search-bar>
        <vsf-collections-menu class="hidden md:block"></vsf-collections-menu>
        <vsf-cart-toggle (toggle)="openCartDrawer()"></vsf-cart-toggle>
        <vsf-account-link></vsf-account-link>
      </div>
    </div>
  </vsf-layout-header>
  <vsf-collections-menu-mobile
    [visible]="mobileNavVisible$ | async"
    class="z-10"
  ></vsf-collections-menu-mobile>
  <vsf-cart-drawer
    (close)="closeCartDrawer()"
    [visible]="cartDrawerVisible$ | async"
  ></vsf-cart-drawer>
  <div class="contents">
    <vsf-home-page *ngIf="isHomePage$ | async; else main"></vsf-home-page>
    <ng-template #main>
      <router-outlet></router-outlet>
    </ng-template>
  </div>

  <vsf-layout-footer>
    <footer aria-labelledby="footer-heading" class="mt-24 border-t bg-gray-50">
      <h2 class="sr-only" id="footer-heading">Footer</h2>
      <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div class="xl:grid xl:grid-cols-3 xl:gap-8">
          <div class="grid grid-cols-2 gap-8 xl:col-span-2">
            <div class="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 class="text-sm font-semibold text-gray-500 tracking-wider uppercase">Shop</h3>
                <ul class="mt-4 space-y-4" role="list">
                  <li *ngFor="let item of topCollections$ | async">
                    <a
                      [routerLink]="['/category', item.slug]"
                      class="text-base text-gray-500 hover:text-gray-600"
                    >
                      {{ item.name }}
                    </a>
                  </li>
                </ul>
              </div>
              <div class="mt-12 md:mt-0">
                <h3 class="text-sm font-semibold text-gray-500 tracking-wider uppercase">
                  Support
                </h3>
                <ul class="mt-4 space-y-4" role="list">
                  <li *ngFor="let item of navigation.support">
                    <a [href]="item.href" class="text-base text-gray-500 hover:text-gray-600">
                      {{ item.name }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 class="text-sm font-semibold text-gray-500 tracking-wider uppercase">
                  Company
                </h3>
                <ul class="mt-4 space-y-4" role="list">
                  <li *ngFor="let item of navigation.company">
                    <a [href]="item.href" class="text-base text-gray-500 hover:text-gray-600">
                      {{ item.name }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="mt-8 xl:mt-0">
            <h3 class="text-sm font-semibold text-gray-500 tracking-wider uppercase">
              Subscribe to our newsletter
            </h3>
            <p class="mt-4 text-base text-gray-500">
              Be the first to know about exclusive offers & deals.
            </p>
            <form class="mt-4 sm:flex sm:max-w-md">
              <label class="sr-only" htmlFor="email-address"> Email address </label>
              <input
                autoComplete="email"
                class="appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-md py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white focus:placeholder-gray-400"
                id="email-address"
                name="email-address"
                placeholder="Enter your email"
                required
                type="email"
              />
              <div class="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <button
                  class="w-full bg-primary-500 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-primary-500"
                  type="submit"
                >
                  Subscribe
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </footer>
  </vsf-layout-footer>
</vsf-layout>
