import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { GetActiveCustomerQuery } from '../../../common/generated-types';
import { GET_ACTIVE_CUSTOMER } from '../../../common/graphql/documents.graphql';
import { DataService } from '../../providers/data/data.service';
import { StateService } from '../../providers/state/state.service';
import { SignOutMutation } from '../../../common/generated-types';
import { SIGN_OUT } from './account-link.graphql';

@Component({
  selector: 'vsf-account-link',
  templateUrl: './account-link.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AccountLinkComponent implements OnInit {
  activeCustomer$: Observable<GetActiveCustomerQuery['activeCustomer']>;

  constructor(
    private dataService: DataService,
    private stateService: StateService,
    private router: Router,
  ) {}

  ngOnInit() {
    const getActiveCustomer$ = this.dataService.query<GetActiveCustomerQuery>({
      query: GET_ACTIVE_CUSTOMER,
      variables: {},
      fetchPolicy: 'network-only',
    });

    getActiveCustomer$.pipe(take(1)).subscribe(data => {
      if (data.activeCustomer) {
        this.stateService.setState('signedIn', true);
      }
    });

    this.activeCustomer$ = this.stateService
      .select(state => state.signedIn)
      .pipe(
        switchMap(() => getActiveCustomer$),
        map(data => data && data.activeCustomer),
      );
  }

  userName(customer: NonNullable<GetActiveCustomerQuery['activeCustomer']>): string {
    const { firstName, emailAddress } = customer;
    if (firstName) {
      return `${firstName}`;
    } else {
      return emailAddress;
    }
  }

  async logOut() {
    this.dataService.mutate<SignOutMutation>(SIGN_OUT).subscribe({
      next: () => {
        this.stateService.setState('signedIn', false);
        this.dataService
          .query<GetActiveCustomerQuery>({
            query: GET_ACTIVE_CUSTOMER,
            fetchPolicy: 'network-only',
          })
          .subscribe();
        this.router.navigate(['/']);
      },
    });
  }
}
