<div *ngIf="activeCustomer$ | async as customer; else notLoggedIn">
  <vsf-dropdown [openOnHover]="true" [position]="['bottom-right']">
    <a
      vsfDropdownTrigger
      class="btn btn-light text-muted space-x-2 hover:opacity-50"
      [routerLink]="['/account']"
    >
      <fa-icon
        [icon]="customer.isLoggedIn ? 'check-circle' : 'user-circle'"
        [ngClass]="{ 'text-green-500': customer.isLoggedIn, 'text-xl': !customer.isLoggedIn }"
      ></fa-icon>
    </a>
    <div
      vsfDropdownContent
      class="mr-[-80px] bg-gradient-to-br from-gray-500 to-zinc-600 rounded-lg shadow-lg py-2 min-w-[200px] mt-2"
    >
      <a
        [routerLink]="['/account', 'details']"
        class="flex items-center px-4 py-2 text-gray-100 hover:bg-gray-700/50 transition-colors duration-200"
      >
        <div class="flex items-center space-x-3">
          <fa-icon icon="user-circle" class="text-xl w-8"></fa-icon>
          <span class="text-base">ข้อมูลส่วนตัว</span>
        </div>
      </a>
      <a
        [routerLink]="['/account', 'orders']"
        class="flex items-center px-4 py-2 text-gray-100 hover:bg-gray-700/50 transition-colors duration-200"
      >
        <div class="flex items-center space-x-3">
          <fa-icon icon="shopping-bag" class="text-xl w-8"></fa-icon>
          <span class="text-base">รายการสั่งซื้อ</span>
        </div>
      </a>
      <a
        [routerLink]="['/account', 'address-book']"
        class="flex items-center px-4 py-2 text-gray-100 hover:bg-gray-700/50 transition-colors duration-200"
      >
        <div class="flex items-center space-x-3">
          <fa-icon icon="address-book" class="text-xl w-8"></fa-icon>
          <span class="text-base">ที่อยู่ปัจจุบัน</span>
        </div>
      </a>
      <a
        [routerLink]="['/account', 'change-credentials']"
        class="flex items-center px-4 py-2 text-gray-100 hover:bg-gray-700/50 transition-colors duration-200"
      >
        <div class="flex items-center space-x-3">
          <fa-icon icon="star" class="text-xl w-8"></fa-icon>
          <span class="text-base">เปลี่ยนรหัสผ่าน</span>
        </div>
      </a>
      <button
        (click)="logOut()"
        class="w-full flex items-center px-4 py-2 text-gray-100 hover:bg-gray-700/50 transition-colors duration-200"
      >
        <div class="flex items-center space-x-3">
          <fa-icon icon="sign-out-alt" class="text-xl w-8"></fa-icon>
          <span class="text-base">ออกจากระบบ</span>
        </div>
      </button>
    </div>
  </vsf-dropdown>
</div>
<ng-template #notLoggedIn>
  <a
    class="btn btn-light text-muted hover:underline-offset-2 hover:underline"
    [routerLink]="['account/sign-in']"
  >
    <fa-icon icon="user-circle" class="text-xl"></fa-icon>
  </a>
</ng-template>
