<nav class="flex" aria-label="Breadcrumb">
  <ol role="list" class="flex items-end space-x-1">
    <li>
      <div class="flex items-end">
        <a
          [routerLink]="['/']"
          class="text-black hover:underline underline-offset-4 font-medium text-sm"
        >
          <fa-icon icon="home" class="mr-1" [size]="'md'" aria-hidden="true"></fa-icon>
          Home
        </a>
      </div>
    </li>
    <ng-container *ngIf="breadcrumbs; else placeholder">
      <li *ngFor="let breadcrumb of tail(breadcrumbs); last as isLast; first as isFirst">
        <div class="flex items-end">
          <span class="mx-1">/</span>
          <div
            class="text-xs md:text-sm font-medium text-black hover:underline underline-offset-4 cursor-pointer"
          >
            <ng-container *ngIf="isLast && !linkLast; else link">{{
              breadcrumb.name
            }}</ng-container>
            <ng-template #link>
              <a [routerLink]="['/category', breadcrumb.slug]">{{ breadcrumb.name }}</a>
            </ng-template>
          </div>
        </div>
      </li>
    </ng-container>
    <ng-template #placeholder>
      <li class="h-6 rounded bg-gray-200 animate-pulse w-64"></li>
    </ng-template>
  </ol>
</nav>
