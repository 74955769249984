<div *ngIf="cart$ | async as cart" class="mr-4">
  <button
    class="flex flex-row hover:underline-offset-2 hover:underline"
    (click)="toggle.emit()"
    title="cart"
  >
    <div class="relative">
      <fa-icon icon="shopping-cart" class="mr-1"></fa-icon>
      <span
        *ngIf="cart.quantity > 0"
        class="absolute -top-2 -right-2 text-white bg-primary-600 rounded-full w-4 h-4 flex items-center justify-center text-xs"
        [class.empty]="cart.quantity === 0"
        [class.cart-changed]="cartChangeIndication$ | async"
        >{{ cart.quantity }}</span
      >
    </div>
  </button>
</div>
