import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

import { GetCollectionsQuery, GetCollectionsQueryVariables } from '../../../common/generated-types';
import { GET_COLLECTIONS } from '../../../common/graphql/documents.graphql';
import { DataService } from '../../../core/providers/data/data.service';

import { arrayToTree, RootNode, TreeNode } from './array-to-tree';
import { StateService } from '../../providers/state/state.service';

type CollectionItem = GetCollectionsQuery['collections']['items'][number];

@Component({
  selector: 'vsf-collections-menu',
  templateUrl: './collections-menu.component.html',
  // styleUrls: ['./collections-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CollectionsMenuComponent implements OnInit, OnDestroy {
  collections$: Observable<CollectionItem[]>;
  collectionTree$: Observable<RootNode<CollectionItem>>;
  activeCollection: TreeNode<CollectionItem> | null;
  isSignedIn$: Observable<boolean>;

  @ViewChild('menuTemplate', { read: TemplateRef, static: false })
  menuTemplate: TemplateRef<any>;

  @ViewChild('menuTrigger', { static: false })
  menuTrigger: ElementRef;

  @ViewChild('collectionsPopup', { static: true })
  collectionsPopup: TemplateRef<any>;

  private closeFn: (() => any) | null = null;
  private closeTimer: any = null;
  private overlayIsOpen$ = new Subject<boolean>();
  private setActiveCollection$ = new Subject<TreeNode<CollectionItem>>();
  private destroy$ = new Subject();
  private overlayRef: OverlayRef | null = null;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private dataService: DataService,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private stateService: StateService,
    private renderer: Renderer2,
  ) {
    this.isSignedIn$ = this.stateService.select(state => state.signedIn);
    // this.count = this.store.select<any>(state => state.count);
    // this.collectionTree$.subscribe(res => console.log(res));
    this.renderer.listen('window', 'click', () => {
      this.closeFn && this.closeFn();
    });
  }

  ngOnInit() {
    this.collections$ = this.dataService
      .query({
        query: GET_COLLECTIONS,
        variables: { options: { take: 50 } },
      })
      .pipe(
        map(({ collections }) => {
          // console.log(collections);
          return collections.items;
        }),
      );
    this.collectionTree$ = this.dataService
      .query<GetCollectionsQuery, GetCollectionsQueryVariables>({
        query: GET_COLLECTIONS,
        variables: {
          options: { take: 50 },
        },
      })
      .pipe(
        map(data => {
          // console.log(data.collections.items);
          return arrayToTree(data.collections.items);
        }),
        map(tree => ({
          ...tree,
          children: tree.children.filter(child => child.parent === null),
        })),
      );
    // this.collectionTree$.subscribe(res => console.log(res));
    this.collections$.subscribe(res => console.log(res));
    this.overlayIsOpen$.pipe(debounceTime(50), takeUntil(this.destroy$)).subscribe(val => {
      if (val) {
        this.openOverlay();
      } else {
        this.closeOverlay();
      }
    });

    this.setActiveCollection$.pipe(debounceTime(0), takeUntil(this.destroy$)).subscribe(val => {
      this.activeCollection = val;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.closePopup();
  }

  onTopLevelClick(event: MouseEvent, collection: TreeNode<CollectionItem>) {
    if (collection.children.length) {
      event.preventDefault();
      event.stopImmediatePropagation();
      this.onMouseEnter(collection);
      this.registerDocumentTouchHandler();
    } else {
      this.closeOverlay();
    }
  }

  captureTouchStart(event: TouchEvent) {
    event.stopPropagation();
  }

  onMouseEnter(collection: TreeNode<CollectionItem>) {
    // console.log(collection);
    // this.setActiveCollection$.next(collection);
    // this.overlayIsOpen$.next(true);
    // TODO: re-enable this
  }

  close(event: any) {
    this.overlayIsOpen$.next(false);
  }

  showCollectionsPopup(event: MouseEvent) {
    if (this.overlayRef) {
      return; // Don't create multiple overlays
    }

    event.preventDefault();
    event.stopPropagation();

    // Create overlay config
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(event.currentTarget as HTMLElement)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
          offsetY: 2,
          offsetX: -4,
        },
      ]);

    const overlayConfig = new OverlayConfig({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });

    // Create overlay
    this.overlayRef = this.overlay.create(overlayConfig);

    // Create portal
    const portal = new TemplatePortal(this.collectionsPopup, this.viewContainerRef);
    this.overlayRef.attach(portal);

    // Store close function
    this.closeFn = () => {
      if (this.overlayRef) {
        this.overlayRef.dispose();
        this.overlayRef = null;
      }
      this.closeFn = null;
    };
  }

  startCloseTimer() {
    this.closeTimer = setTimeout(() => {
      this.closePopup();
    }, 150); // Small delay to allow moving mouse to popup
  }

  cancelCloseTimer() {
    if (this.closeTimer) {
      clearTimeout(this.closeTimer);
      this.closeTimer = null;
    }
  }

  closePopup() {
    this.cancelCloseTimer();
    if (this.closeFn) {
      console.log('closing popup');

      this.closeFn();
    }
  }

  private openOverlay() {
    if (this.closeFn) {
      return;
    }
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.viewContainerRef.element)
      .withPositions([
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        },
      ])
      .withPush(false);
    const scrollStrategy = this.overlay.scrollStrategies.reposition();
    const overlayRef = this.overlay.create(
      new OverlayConfig({
        scrollStrategy,
        positionStrategy,
        minWidth: '100vw',
        maxHeight: 500,
      }),
    );
    this.closeFn = () => {
      overlayRef.dispose();
      this.closeFn = null;
    };
    overlayRef.attach(new TemplatePortal(this.menuTemplate, this.viewContainerRef));
  }

  private closeOverlay() {
    if (typeof this.closeFn === 'function') {
      this.closeFn();
    }
  }

  private registerDocumentTouchHandler = () => {
    const handler = () => {
      this.closeOverlay();
      this.document.removeEventListener('touchstart', handler);
    };
    this.document.addEventListener('touchstart', handler);
  };
}
