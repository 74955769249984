<div class="max-w-7xl mx-auto px-10 flex items-center">
  <ul class="flex space-x-4 md:space-x-4 xl:space-x-8" *ngIf="collectionTree$ | async as tree">
    <li class="nav-item cursor-pointer text-md hover:underline underline-offset-4">
      <a [routerLink]="['/']">หน้าหลัก</a>
    </li>
    <li class="nav-item cursor-pointer text-md hover:underline underline-offset-4 relative">
      <a
        [routerLink]="['/category/all']"
        (mouseenter)="showCollectionsPopup($event)"
        class="inline-block"
        #menuTrigger
        >หมวดหมู่สินค้า</a
      >
    </li>
    <li
      class="nav-item cursor-pointer text-md hover:underline underline-offset-4"
      *ngIf="(isSignedIn$ | async) === false"
    >
      <a [routerLink]="['/account/register']">สมัครสมาชิก</a>
    </li>
    <li class="nav-item cursor-pointer text-md hover:underline underline-offset-4">
      <a [routerLink]="['/contact']">ติดต่อ</a>
    </li>
  </ul>
</div>

<ng-template #menuTemplate>
  <div
    class="collections-menu-sub-collections"
    *ngIf="activeCollection?.children?.length"
    (touchstart)="captureTouchStart($event)"
    (mouseenter)="onMouseEnter(activeCollection)"
    (mouseleave)="close()"
  >
    <div class="container d-flex flex-wrap">
      <div class="level-1-block" *ngFor="let level1 of activeCollection.children">
        <vsf-collection-card [collection]="level1"></vsf-collection-card>
        <ng-container *ngFor="let level2 of level1.children">
          <a class="level-2-link" [routerLink]="['/category', level2.slug]" (click)="close()">
            {{ level2.name }}
          </a>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #collectionsPopup>
  <div
    class="collections-popup bg-white shadow-lg rounded-lg p-4 min-w-[120px]"
    (mouseenter)="cancelCloseTimer()"
    (mouseleave)="startCloseTimer()"
  >
    <div class="grid grid-cols-2 gap-2">
      <div *ngFor="let collection of collections$ | async" class="p-2 hover:bg-gray-100 rounded">
        <a [routerLink]="['/category', collection.slug]" class="block" (click)="closePopup()">
          {{ collection.name }}
        </a>
      </div>
    </div>
  </div>
</ng-template>
