<form [formGroup]="addressForm">
  <div class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
    <div>
      <label htmlFor="fullName" class="block text-sm font-medium text-gray-700">
        ชื่อ-นามสกุล
      </label>
      <div class="mt-1">
        <input
          type="text"
          id="fullName"
          name="fullName"
          formControlName="fullName"
          autoComplete="given-name"
          class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
        />
      </div>
    </div>

    <div class="sm:col-span-2">
      <label htmlFor="company" class="block text-sm font-medium text-gray-700">
        บริษัท <span class="text-gray-400">(Optional)</span>
      </label>
      <div class="mt-1">
        <input
          type="text"
          name="company"
          id="company"
          formControlName="company"
          class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
        />
      </div>
    </div>

    <div class="sm:col-span-2">
      <label htmlFor="streetLine1" class="block text-sm font-medium text-gray-700"> ที่อยู่ </label>
      <div class="mt-1">
        <input
          type="text"
          name="streetLine1"
          id="streetLine1"
          formControlName="streetLine1"
          autoComplete="street-address"
          class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
        />
      </div>
    </div>

    <div class="sm:col-span-2">
      <label htmlFor="streetLine2" class="block text-sm font-medium text-gray-700">
        ชั้น, อาคาร, หมู่บ้าน
      </label>
      <div class="mt-1">
        <input
          type="text"
          name="streetLine2"
          id="streetLine2"
          formControlName="streetLine2"
          class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
        />
      </div>
    </div>

    <div>
      <label htmlFor="city" class="block text-sm font-medium text-gray-700"> อำเภอ </label>
      <div class="mt-1">
        <input
          type="text"
          name="city"
          id="city"
          autoComplete="address-level2"
          formControlName="city"
          class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
        />
      </div>
    </div>
    <div>
      <label htmlFor="province" class="block text-sm font-medium text-gray-700"> จังหวัด </label>
      <div class="mt-1">
        <input
          type="text"
          name="province"
          id="province"
          formControlName="province"
          autoComplete="address-level1"
          class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
        />
      </div>
    </div>
    <div>
      <label htmlFor="countryCode" class="block text-sm font-medium text-gray-700"> ประเทศ </label>
      <div class="mt-1">
        <select
          class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
          formControlName="countryCode"
          id="countryCode"
        >
          <option *ngFor="let country of availableCountries" [ngValue]="country.code">
            {{ country.name }}
          </option>
        </select>
      </div>
    </div>

    <div>
      <label htmlFor="postalCode" class="block text-sm font-medium text-gray-700">
        รหัสไปรษณีย์
      </label>
      <div class="mt-1">
        <input
          type="text"
          name="postalCode"
          id="postalCode"
          formControlName="postalCode"
          autoComplete="postal-code"
          class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
        />
      </div>
    </div>

    <div class="sm:col-span-2">
      <label htmlFor="phoneNumber" class="block text-sm font-medium text-gray-700">
        เบอร์โทรติดต่อ
      </label>
      <div class="mt-1">
        <input
          type="text"
          name="phoneNumber"
          id="phoneNumber"
          formControlName="phoneNumber"
          autoComplete="tel"
          class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
        />
      </div>
    </div>
  </div>
</form>
