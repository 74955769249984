<div class="bg-white text-sm">
  <h6 class="font-medium text-gray-600" *ngIf="title">{{ title }}</h6>
  <div class="font-bold text-xl">ที่อยู่ปัจจุบัน</div>
  <hr class="my-2" />
  <div class="flex flex-row w-full">
    <div class="w-full space-y-1">
      <div class="text-md">{{ address.fullName }}</div>
      <div class="flex flex-row space-y-2">
        {{ address.streetLine1 }}
        {{ address.postalCode }}
        {{ getCountryName() }}
      </div>
      <div>
        {{ address.phoneNumber }}
      </div>
    </div>
    <div class="flex items-center justify-center">
      <a class="text-blue-900 hover:underline" [routerLink]="['./', address.id]"> แก้ไข </a>
    </div>
  </div>
  <ng-content></ng-content>
</div>
