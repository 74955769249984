<div *ngIf="cart" class="flow-root">
  <ul role="list" class="divide-y divide-gray-200">
    <ng-container *ngFor="let line of cart.lines; trackBy: trackByFn">
      <li class="py-6 flex">
        <div class="flex-shrink-0 w-24 h-24 border border-gray-200 rounded-md overflow-hidden">
          <img
            [src]="line.featuredAsset | assetPreview: 'thumb'"
            [alt]="line.productVariant.name"
            class="rounded"
          />
        </div>

        <div class="ml-4 flex-1 flex flex-col">
          <div>
            <div class="flex justify-between text-base font-medium text-gray-900">
              <h3>
                <a [routerLink]="['/products', line.productVariant]">
                  {{ line.productVariant.name }}
                </a>
              </h3>
              <p class="ml-4">
                {{ line.linePriceWithTax | formatPrice }}
              </p>
            </div>
          </div>
          <div class="flex-1 flex items-center justify-between text-sm text-gray-600">
            <div class="flex space-x-4">
              <button class="btn btn-sm" *ngIf="canAdjustQuantities" (click)="decrement(line)">
                <fa-icon aria-label="Remove 1" icon="minus"></fa-icon>
              </button>
              <div class="qty">{{ line.quantity }}</div>
              <button class="btn btn-sm" *ngIf="canAdjustQuantities" (click)="increment(line)">
                <fa-icon aria-label="Add 1" icon="plus"></fa-icon>
              </button>
            </div>
            <div class="total">
              <div class="">
                {{ line.unitPriceWithTax | formatPrice }}
              </div>
            </div>
          </div>
        </div>
      </li>
    </ng-container>
  </ul>
</div>
